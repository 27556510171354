.navButtons{
	.btn-default{
		padding:2px 2em;
		margin-right: 1em;
	}
}


.userTableWrap, .roleTableWrap{
	margin: 80px auto 0 auto;
	width:70%;
}


.listUsers{

	td{
		text-align: left;
	}
	tr td:last-child{
		width:60px;
	}
	.btn{
		padding: 0px 12px;
	}
  
}

.profile{
	.form-group{
		label{
			font-weight: normal;
		}
	}
	.has-feedback label ~ .form-control-feedback{
		top: 37px;
	}
	h3 {
		position: relative;
    	left: -7px;
	}
}

/* passwordchangeneeded page */
[data-route="passwordchangeneeded"],[data-route="2faenter"]{ 
	.pageTitle{
		margin-bottom: 2em;
	}
	.navLinks{
		display: none;
	}
}

/* translations page */
[data-route="translations"]{
	.form-group{
	    margin-bottom: 0px;
	}
	.help-block{
		position:absolute;
	}
	.filterLabel{
		width: 30%;
	    display: inline-block;
	    margin-top: 0.52em;
	}
}

[data-route="settings"]{
	label{
		font-weight: normal;
	}
	li.ui-state-default{
		list-style-type: none;
		cursor: ns-resize;
		margin:10px;
	}
	.filloutsubmission{
		ul{
			width:70%;
			float:right;
			/*display: inline-block;*/
		}
		div.name{
			width:28%;
			float:left;
			/*display: inline-block;*/
		}
	}
}

[data-route="status"] .contentWrap{

	.fa-check{
		color:green;
	}
	.fa-times{
		color:red;
	}
	.fa{
		margin-left:10px;
	}
	.fa:first-of-type{
		margin-left:0;	
	}
}